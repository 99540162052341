import React from "react";
import "./SpecialEvent.css";
import specialImg from "../../../Assets/ev-1.png";
import specialImg1 from "../../../Assets/ev-2.png";
import specialImg2 from "../../../Assets/ev-3.png";
// import specialImg3 from "../../../Assets/ev3.png";
// import specialImg4 from "../../../Assets/ev4.png";

function SpecialEvent() {
  return (
    <div className="section-special">
      <div className="special-content">
        <h2>
          SATURDAY LIVE MUSIC <br />
          7:00PM TO 10.00PM <br />
          & <br />
          Holiday Special Meal <br />
        </h2>

        <div className="special-content-img">
          <img alt="special" src={specialImg} />
          {/* <img alt="special" src={specialImg1} /> */}
          s <img alt="special" src={specialImg2} />
          {/* <img alt="special" src={specialImg4} /> */}
        </div>
        {/* <div className="special-content-2">
          <img alt="special" src={specialImg} />
        </div> */}
      </div>
    </div>
  );
}

export default SpecialEvent;
